

$(document).ready(function(){

  $("#form_search_pub").hide();
  $('#btn_hide_form_search_pub').hide();

//show the publication search form
  $('#btn_show_form_search_pub').click(function(){

    $('#form_search_pub').fadeIn(300);
    $('#btn_hide_form_search_pub').show();
    $('#btn_show_form_search_pub').hide();
  });

  //hide the publication search form
  $('#btn_hide_form_search_pub').click(function(){
    $('#form_search_pub').fadeOut(300);
    $('#btn_hide_form_search_pub').hide();
    $('#btn_show_form_search_pub').show();
  });


  // $("#exampleModal").modal('show');


  

});
