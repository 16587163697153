// # Place all the behaviors and hooks related to the matching controller here.
// # All this logic will automatically be available in application.js.
// # You can use CoffeeScript in this file: http://coffeescript.org/


//hide or show the faq answer
$(document).on('click', '.faq-li .faq-list', function(){
  $(this).closest(".faq-li").find(".faq-ans").fadeToggle(300);
  $(this).closest(".faq-li").find(".plus").fadeToggle(10);
  $(this).closest(".faq-li").find(".minus").fadeToggle(10);
});


//expand or close all questions ans
$(document).ready(function(){
	$(".expand-all").click(function(){
		$(".faq-ans").show();
		$(".close-all").show();
		$(".expand-all").hide();
		$(".plus").hide();
		$(".minus").show();
	});  
});

$(document).ready(function(){
	$(".close-all").click(function(){
		$(".faq-ans").hide();
		$(".close-all").hide();
		$(".expand-all").show();
		$(".plus").show();
		$(".minus").hide();
	});  
});


