import consumer from "./consumer"


consumer.subscriptions.create("NotificationsChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log('connected to Notifications Channel...');
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
   if (document.getElementById("flash-alert")) {
   		document.getElementById("flash-alert").innerHTML = '';
   		document.getElementById("flash-alert").style.display = 'none';
   	} 

  
   document.getElementById("notifications").innerHTML = data.html;

    if(document.getElementById("searchModal")) {
      $("#searchModal").modal('show');
    }
  }

});
