$(document).ready(function(){

//initially hiding the search forms

  //******************QUERIES************************
  $("#form_search_qry").hide();
  $('#btn_hide_form_search_qry').hide();


  // //show the query search form
  // $('#btn_show_form_search_qry').click(function(){

  //   $('#form_search_qry').fadeIn(300);
  //   $('#btn_hide_form_search_qry').show();
  //   $('#btn_show_form_search_qry').hide();

  // });

  // //hide the query search form
  // $('#btn_hide_form_search_qry').click(function(){

  //   $('#form_search_qry').fadeOut(300);
  //   $('#btn_hide_form_search_qry').hide();
  //   $('#btn_show_form_search_qry').show();

  // });


  // $('#popover').popover({
  //   trigger: 'focus'
  // })


// switch the buttons on the query submition index page
    $('#all').hide(); 
    $('#selective').show(); 
    $("#search_all").prop("checked", false);

    $('#search_all').change(function () {
      if (this.checked){
        $('#selective').hide();
        $('#all').show();
      }else{
        $('#selective').show();
        $('#all').hide();
      }
    });



  


    $("tr[data-link]").click(function() {
        window.location = $(this).data('link');
    });
 

  });

//****************END QUERY*****************************
