// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("@rails/ujs").start()
require("turbolinks").start()
require('src/query.js')
require('src/search.js')
require('src/publication.js')
require('src/question.js')
require('src/structure.js')
require("chartkick")
require("chart.js")
require('data-confirm-modal')
// require('bootstrap_custom.js')
import 'bootstrap/dist/js/bootstrap';
import "@fortawesome/fontawesome-free/js/all";


// show drop downs on hover
$(document).ready(function(){
    $('.navbar .dropdown').hover(function() {
        $(this).find('.dropdown-menu').first().stop(true, true).delay(250).slideDown();
    }, function() {
        $(this).find('.dropdown-menu').first().stop(true, true).delay(100).slideUp()
    });


    $(function () {
    	$('#popover').popover({trigger: "hover"});
	});

})

