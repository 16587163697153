$(document).ready(function(){
  // $("#form_search_srch").hide();
  // $('#btn_hide_form_search_srch').hide();


  //show the query search form
  // $('#btn_show_form_search_srch').click(function(){

  //   $('#form_search_srch').fadeIn(300);
  //   $('#btn_hide_form_search_srch').show();
  //   $('#btn_show_form_search_srch').hide();

  // });

  //hide the query search form
  // $('#btn_hide_form_search_srch').click(function(){

  //   $('#form_search_srch').fadeOut(300);
  //   $('#btn_hide_form_search_srch').hide();
  //   $('#btn_show_form_search_srch').show();

  // });

});
